import React from 'react';
import Layout from '../components/CleanLayout';
import Thankyou from '../components/thankyou/Thankyou';

const METADATA = [
  {
    name: 'description',
    content:
      "Thank you for registering for Subtle Medical's RSNA Lunch and Learn",
  },
  {
    name: 'keywords',
    content: 'RSNA, lunch and learn, PET, MRI, radiology, AI, Subtle Medical',
  },
];

const index = () => (
  <Layout pageTitle="Thank You for Registering" metadata={METADATA}>
    <Thankyou>
      <p className="thankyou--subtext">
        You'll receive a confirmation email soon. If you can't find it, please
        check your spam folder.
      </p>
      <p
        style={{
          margin: '0px 0px 10px 0px',
          textAlign: 'center',
          fontSize: '17px',
          lineHeight: '150%',
          color: '#000',
          fontWeight: 'bold',
        }}
      >
        Add Subtle Lunch and Learn to calendar:
      </p>
      <p
        style={{
          margin: '0px 0px 10px 0px',
          textAlign: 'center',
          fontSize: '0px',
        }}
      >
        <a
          href="https://www.addevent.com/event/hm4078505+apple"
          title="Apple"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-apple-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          href="https://www.addevent.com/event/hm4078505+google"
          title="Google"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-google-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          href="https://www.addevent.com/event/hm4078505+outlook"
          title="Outlook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-outlook-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          href="https://www.addevent.com/event/hm4078505+outlookcom"
          title="Outlook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-outlookcom-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
        <a
          href="https://www.addevent.com/event/hm4078505+yahoo"
          title="Yahoo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.addevent.com/gfx/icon-emd-share-yahoo-t1.png"
            alt=""
            width="45"
            border="0"
          />
        </a>
      </p>
    </Thankyou>
  </Layout>
);

export default index;
